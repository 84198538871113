<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card wide-modal">
      <header class="modal-card-head">
        <p class="modal-card-title">Landed Cost Params</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body" v-if="product">
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Id Index</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input is-static" type="text" :value="product.idIndex" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Item</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input is-static" type="text" :value="product.itemName" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Container Cost</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">CA</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="containerCosts.CA">
              </p>
              <p class="control">
                <a class="button is-static">NY</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="containerCosts.NY">
              </p>
              <p class="control">
                <a class="button is-static">TX</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="containerCosts.TX">
              </p>
              <p class="control">
                <a class="button is-static">ON</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="containerCosts.ON">
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Qty per Container</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input" type="number" v-model.number="qtyPerContainer">
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Freights</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">CA</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="freights.CA" readonly>
              </p>
              <p class="control">
                <a class="button is-static">NY</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="freights.NY" readonly>
              </p>
              <p class="control">
                <a class="button is-static">TX</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="freights.TX" readonly>
              </p>
              <p class="control">
                <a class="button is-static">ON</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="freights.ON" readonly>
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Eric Purchase Price</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input" type="number" v-model.number="ericPurchasePrice">
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Duty Percentage</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">US</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="dutyPercentage">
              </p>
              <p class="control">
                <a class="button is-static">Canada</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="dutyPercentageCanada">
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Duty</label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">US</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="duty" readonly>
              </p>
              <p class="control">
                <a class="button is-static">Canada</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="dutyCanada" readonly>
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Future Landed Cost</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <input class="input is-static has-text-weight-bold" type="number" :value="futureLandedCosts.US" readonly>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label"></label>
          </div>
          <div class="field-body">
            <div class="field has-addons">
              <p class="control">
                <a class="button is-static">CA</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="futureLandedCosts.CA" readonly>
              </p>
              <p class="control">
                <a class="button is-static">NY</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="futureLandedCosts.NY" readonly>
              </p>
              <p class="control">
                <a class="button is-static">TX</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="futureLandedCosts.TX" readonly>
              </p>
              <p class="control">
                <a class="button is-static">ON</a>
              </p>
              <p class="control">
                <input class="input cost-input" type="number" v-model.number="futureLandedCosts.ON" readonly>
              </p>
            </div>
          </div>
        </div>

        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!isValid" @click="updateParams">Update</a>
        <a class="button" @click="close">Cancel</a>
      </footer>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'landed-cost-params-modal',
  props: ['opened', 'product'],
  data () {
    return {
      error: '',
      waiting: false,
      ericPurchasePrice: 0,
      dutyPercentage: 0,
      dutyPercentageCanada: 0,
      containerCosts: {CA: 0, NY: 0, TX: 0, ON: 0},
      qtyPerContainer: 0,
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    isValid () {
      return this.ericPurchasePrice && this.containerCosts.CA && this.containerCosts.NY && this.containerCosts.TX
        && this.containerCosts.ON && this.qtyPerContainer
    },
    freights () {
      if (this.qtyPerContainer) {
        return {
          CA: this.roundNum(this.containerCosts.CA / this.qtyPerContainer),
          NY: this.roundNum(this.containerCosts.NY / this.qtyPerContainer),
          TX: this.roundNum(this.containerCosts.TX / this.qtyPerContainer),
          ON: this.roundNum(this.containerCosts.ON / this.qtyPerContainer),
        }
      }
      return {CA: 0, NY: 0, TX: 0, ON: 0}
    },
    duty () {
      return this.roundNum(this.ericPurchasePrice * this.dutyPercentage / 100)
    },
    dutyCanada () {
      return this.roundNum(this.ericPurchasePrice * this.dutyPercentageCanada / 100)
    },
    futureLandedCosts () {
      var ca = 0
      if (this.freights.CA) {
        ca = this.roundNum(this.ericPurchasePrice + this.freights.CA + this.duty)
      }
      var ny = 0
      if (this.freights.NY) {
        ny = this.roundNum(this.ericPurchasePrice + this.freights.NY + this.duty)
      }
      var tx = 0
      if (this.freights.TX) {
        tx = this.roundNum(this.ericPurchasePrice + this.freights.TX + this.duty)
      }
      var us = this.roundNum((ca + ny + tx) / 3)
      var on = 0
      if (this.freights.ON) {
        on = this.roundNum(this.ericPurchasePrice + this.freights.ON + this.dutyCanada)
      }
      return {CA: ca, NY: ny, TX: tx, US: us, ON: on}
    },
  },
  watch: {
    product: function (val) {
      if (val) {
        var f = val.futureLandedCost
        this.ericPurchasePrice = f.ericPurchasePrice ? f.ericPurchasePrice : 0
        this.dutyPercentage = f.dutyPercentage ? f.dutyPercentage : 0
        this.dutyPercentageCanada = f.dutyPercentageCanada ? f.dutyPercentageCanada : 0
        this.containerCosts = f.containerCosts ? f.containerCosts : {CA: 0, NY: 0, TX: 0, ON: 0}
        this.qtyPerContainer = f.qtyPerContainer ? f.qtyPerContainer : 0
      }
    },
  },
  methods: {
    close () {
      this.$emit('closeLandedCostParamsModal')
    },
    updateParams () {
      if (this.waiting || !this.isValid) {
        return
      }
      this.waiting = true
      var message = {
        productId: this.product.id,
        ericPurchasePrice: this.ericPurchasePrice,
        dutyPercentage: this.dutyPercentage,
        dutyPercentageCanada: this.dutyPercentageCanada,
        containerCosts: this.containerCosts,
        qtyPerContainer: this.qtyPerContainer,
      }
      this.$http.post(this.server + '/myapp/update-landed-cost-params/', message).then(resp => {
        this.waiting = false
        this.$emit('closeLandedCostParamsModal', resp.body)
      }, err => {
        this.error = err.body
        this.waiting = false
      })
    },
    roundNum (n) {
      return Math.round(n * 100) / 100
    },
  },
}
</script>

<style scoped>
.wide-modal {
  width: 800px!important;
}
</style>

<template>
  <div class="modal" :class="{'is-active': opened}">
    <div class="modal-background"></div>
    <div class="modal-card wide-modal">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Container Price to All</p>
        <button class="delete" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div>
          <label class="label">Container Price</label>
          <div class="field has-addons">
            <p class="control">
              <a class="button is-static">CA</a>
            </p>
            <p class="control">
              <input class="input cost-input" type="number" v-model.number="containerCosts.CA">
            </p>
            <p class="control">
              <a class="button is-static">NY</a>
            </p>
            <p class="control">
              <input class="input cost-input" type="number" v-model.number="containerCosts.NY">
            </p>
            <p class="control">
              <a class="button is-static">TX</a>
            </p>
            <p class="control">
              <input class="input cost-input" type="number" v-model.number="containerCosts.TX">
            </p>
            <p class="control">
              <a class="button is-static">ON</a>
            </p>
            <p class="control">
              <input class="input cost-input" type="number" v-model.number="containerCosts.ON">
            </p>
          </div>
        </div>
        <div v-if="error" class="notification is-danger is-light">
          <button class="delete" @click="error=''"></button>
          {{error}}
        </div>
      </section>
      <footer class="modal-card-foot">
        <a class="button is-link" :class="{'is-loading': waiting}" :disabled="costDisabled" @click="updateAll">Update All</a>
        <a class="button" @click="close">Cancel</a>
        <p class="help is-info" v-if="waiting">{{progress}} / {{products.length}}</p>
      </footer>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'container-cost-modal',
  props: ['opened', 'products'],
  data () {
    return {
      error: '',
      waiting: false,
      progress: 0,
      total: 0,
      containerCosts: {CA: 0, NY: 0, TX: 0, ON: 0},
    }
  },
  computed: {
    server () {
      return this.$store.state.config.server
    },
    costDisabled () {
      return !this.containerCosts.CA || !this.containerCosts.NY || !this.containerCosts.TX || !this.containerCosts.ON
    },
  },
  watch: {
    products: function (val) {
      if (val && val.length) {
        for (var p of val) {
          if (p.futureLandedCost.containerCosts) {
            this.containerCosts = p.futureLandedCost.containerCosts
            break
          }
        }
      }
    },
  },
  methods: {
    close () {
      this.$emit('closeContainerCostModal')
    },
    updateAll () {
      if (this.waiting || this.costDisabled) {
        return
      }
      this.waiting = true
      this.progress = 0
      var messages = []
      for (var p of this.products) {
        var message = this.makeRequestMessage(p)
        if (message) {
          messages.push(message)
        }
      }
      this.total = messages.length
      var vm = this
      var promises = messages.map(m => vm.$http.post(vm.server + '/myapp/update-landed-cost-params/', m).then(resp => {
        vm.progress += 1
      }))
      Promise.all(promises).then(resp => {
        var result = {}
        for (var m of messages) {
          result[m.productId] = {
            ericPurchasePrice: m.ericPurchasePrice,
            dutyPercentage: m.dutyPercentage,
            qtyPerContainer: m.qtyPerContainer,
            containerCosts: m.containerCosts,
          }
        }
        vm.waiting = false
        this.$emit('closeContainerCostModal', result)
      }, err => {
        vm.error = err.body
        vm.waiting = false
      })
    },
    makeRequestMessage (p) {
      var f = p.futureLandedCost
      if (f.ericPurchasePrice && f.qtyPerContainer) {
        return {
          productId: p.id,
          ericPurchasePrice: f.ericPurchasePrice,
          dutyPercentage: f.dutyPercentage ? f.dutyPercentage : 0,
          dutyPercentageCanada: f.dutyPercentageCanada ? f.dutyPercentageCanada : 0,
          qtyPerContainer: f.qtyPerContainer,
          containerCosts: this.containerCosts,
        }
      }
    }
  },
}
</script>

<style scoped>
.cost-input {
  width: 120px;
}

.wide-modal {
  width: 800px!important;
}

</style>